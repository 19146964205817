body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.colum-container {
  flex-direction: column;
  display: flex;
}

.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.p2 {
  padding: 2px;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}

.text-border-green {
  padding: 3px 10px;
  border: 1px solid #0ef731;
  border-radius: 15px;
  color: #0ef731;
}

.text-border-red {
  padding: 3px 10px;
  border: 1px solid #a23e48;
  border-radius: 15px;
  color: #a23e48;
}

.text-filled-red {
  font-size: smaller;
  display: inline-block;
  padding: 0px 5px;
  border: 1px solid #a23e48;
  border-radius: 15px;
  color: white;
  background-color: #a23e48;
}