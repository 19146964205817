/* Navbar */

.navbar {
    padding-bottom: 1px;
    border-bottom: solid 1px #e8e8e8;
    background-color: white;
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
      0 6px 16px -11px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 0;
    margin-bottom: 10px;
    z-index: 99990;
  }

  .menuButton {
    float: left;
    height: 30px;
    margin-top: 15px;
    color: #0dd839;
    display: block;
  }
  
  .nav-header {
    background-color: white;
    border-bottom: 0;
    padding: 0 10px;
  }

  .nav-header-item {
    padding: 5px 20px;
    line-height: 2!important;
  }

  .nav-container-between {
    display: flex;
    justify-content: space-between;
  }

  .nav-column {
    display: flex;
    flex-direction: column;
  }

    .nav-text {
        padding: 0!important;
        margin: 0!important;
        line-height: 1!important;
    }
  /* Navbar End */